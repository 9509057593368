import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Styled from "./style";

import { Helmet } from "react-helmet";

import { useAppContext } from "../../context/AppContext";
import { SearchInput } from "../../components/SearchInput";

import { GoClock } from "react-icons/go";
import { CiImageOn } from "react-icons/ci";

import bot from "../../assets/home/bot.png";
import close from "../../assets/close.png";
import search from "../../assets/home/8.png";
import Title from "../../assets/title.png";

import { real_data } from "../../constant/temp";
import { format } from "date-fns";
import isEmpty from "../../utils/isEmpty";
import useOutsideClick from "../../utils/useOutside";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Home = () => {
  const modalRef = useRef();
  const [context] = useAppContext();
  const [result, setResult] = useState("");
  const [searchVisible, onSearchVisible] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null); // State to hold the image URL
  const [fileData, setFileData] = useState(null); // State to hold the image URL

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger the click event on the hidden file input
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileData(file);
    if (file) {
      const reader = new FileReader();
      console.log(reader);
      reader.onload = (e) => {
        setImageSrc(e.target.result); // Set the image URL in the state
      };

      reader.readAsDataURL(file); // Convert the image file to a data URL
    }
  };
  const handleKeyPress = async (event, result) => {
    if (event.key === "Enter" && result) {
      if (context.auth !== null) {
        await axios.post(`https://api.galambo.com/manage/addhistory`, {
          id: context.auth.user.id,
          history: result,
        });
      }
      navigate(`/search/${result}`);
    }
  };

  const handleSelect = async (result) => {
    if (imageSrc) {
      if (!fileData) {
        alert("Please select a file first.");
        return;
      }

      const formData = new FormData();
      formData.append("file", fileData);

      try {
        const response = await axios.post(
          "https://berrygoodmedia.herokuapp.com/https://ml.galambo.com/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("File uploaded successfully", response.data);
      } catch (error) {
        console.error("Error uploading the file:", error);
      }
      // if (context.auth !== null) {
      //   await axios.post(`https://api.galambo.com/manage/addhistory`, {
      //     id: context.auth.user.id,
      //     history: result,
      //   });
      // }
      // navigate(`/upload/${result}`);
    }
    if (result) {
      if (context.auth !== null) {
        await axios.post(`https://api.galambo.com/manage/addhistory`, {
          id: context.auth.user.id,
          history: result,
        });
      }
      navigate(`/search/${result}`);
    }
  };
  useEffect(() => {
    if (context.auth !== null) {
      const getHistory = async () => {
        const data = await axios.post(
          "https://api.galambo.com/manage/gethistory",
          { id: context.auth.user.id }
        );
        if (data) {
          setHistoryData(data.data.data);
        }
      };
      getHistory();
    }
  }, [context, searchVisible]);
  useOutsideClick({
    ref: modalRef,
    handler: () => onSearchVisible(false),
  });
  const handleHistorySelect = async (result) => {
    if (result) {
      navigate(`/search/${result}`);
      onSearchVisible(false);
    }
  };
  return (
    <Styled.StyledHome itemScope itemType="http://schema.org/WebPage">
      <Styled.SearchWrapper position={searchVisible ? "show" : "hide"}>
        <img
          onClick={() => onSearchVisible(false)}
          src={close}
          alt="close icon"
          width={12}
          height={12}
          style={{ position: "fixed", right: "18px", top: "18px" }}
        />
        <div ref={modalRef}>
          <p>Search History</p>
          <div>
            {!isEmpty(historyData) ? (
              historyData.history?.map((item, key) => (
                <div key={key}>
                  <p>{format(new Date(item.date), "MM/dd/yyyy")}</p>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => handleHistorySelect(item.keyword)}
                  >
                    {item.keyword}
                  </p>
                </div>
              ))
            ) : (
              <p>No history</p>
            )}
          </div>
        </div>
      </Styled.SearchWrapper>
      <Helmet>
        <title>Home Page | Galambo</title>
        <meta name="description" content="Home page of Galambo" />
        <link rel="canonical" href="http://www.galambo.com/" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "Home Page | Galambo",
            description: "Home page of Galambo",
            url: "http://www.galambo.com/",
            image: {
              "@type": "ImageObject",
              url: "http://www.galambo.com/assets/home/bot.png",
              width: 294,
              height: 163,
            },
          })}
        </script>
      </Helmet>
      <img
        itemProp="image"
        src={bot}
        alt="bot_image"
        width={294}
        height={163}
      />

      <img
        itemProp="image"
        src={Title}
        alt="title img"
        width={208}
        height={33}
      />
      <h1 itemProp="headline">See the World Differently</h1>

      <Styled.SearchInbox>
        <Styled.SearchSection>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*" // Accept only image files
            style={{ display: "none" }} // Hide the file input
            onChange={handleFileChange}
          />
          <div>
            {imageSrc && (
              <LazyLoadImage
                src={imageSrc}
                alt="search_iamge"
                effect="blur"
                wrapperProps={{ style: { transitionDelay: "1s" } }}
                style={{
                  objectFit: "contain",
                  width: "40px",
                  borderRadius: "10px",
                }}
              />
            )}
          </div>
          <SearchInput
            word={result}
            setResult={setResult}
            handleKeyPress={handleKeyPress}
            handleSelect={handleSelect}
          />
          <Styled.IconSection>
            <Styled.ImageLoader>
              <CiImageOn size={24} color="#000" onClick={handleButtonClick} />
            </Styled.ImageLoader>
            {context.auth !== null && (
              <Styled.HistoryView
                onClick={() => onSearchVisible(!searchVisible)}
              >
                <GoClock size={24} color="#000" />
              </Styled.HistoryView>
            )}
            <Styled.SearchIcon onClick={() => handleSelect(result)}>
              <img itemProp="image" src={search} alt="search icon" width={30} />
            </Styled.SearchIcon>
          </Styled.IconSection>
        </Styled.SearchSection>
      </Styled.SearchInbox>
      <h2>Popular topics...</h2>
      <Styled.TagSelection>
        {real_data.map((item, key) => (
          <Styled.TagBtn
            key={key}
            onClick={() => navigate(`/search/${item.title}`)}
          >
            <img itemProp="image" src={item.img} alt="img_item" />
            <span itemProp="name">{item.title}</span>
          </Styled.TagBtn>
        ))}
      </Styled.TagSelection>
    </Styled.StyledHome>
  );
};

export default Home;
