import styled from "styled-components";

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh- 150px);
  /* margin-bottom: 100px; */
  border-top: 2px solid #d1d1d1;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* max-width: 1440px; */
  width: 100%;
  margin: 0 auto;
`;

export const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-left: 42px;
  margin-right: 10px;
`;

export const userListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 25px;
  h1 {
    font-size: 26px;
    font-weight: 600;
    color: #000000;
  }
`;

export const UserListCotnent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 260px);
  div {
    padding: 14px 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    border-bottom: 1px solid #eaecf0;
    margin-right: 15px;
    span {
      font-size: 14px;
      font-weight: 500;
      color: #101828;
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
`;

export const MessageContainr = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-left: 2px solid #d1d1d1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const MessageHeader = styled.div`
  width: 100%;
  /* padding: 14px 28px; */
  border-bottom: 1px solid #d1d1d1;
  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 28px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      img {
        width: 57px;
        height: 57px;
      }
      p {
        margin: 0;
        font-size: 20px;
        font-weight: 500;
      }
    }
    svg {
      cursor: pointer;
    }
  }
`;

export const MessageContent = styled.div`
  display: flex;
  /* flex: 1; */
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const MessageInput = styled.div`
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    border-radius: 24px;
    margin: 0 14px;
    gap: 10px;
    padding: 12px 30px 12px 20px;
    color: #8080808c;
    input {
      border: none;
      width: 100%;
      background-color: #f6f6f6;
      outline: none;
      font-size: 13px;
    }
  }
  svg {
    cursor: pointer;
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 265px;
  width: 100%;
`;

export const DetailHeader = styled.div`
  border-left: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  padding: 43px 10px 10px 10px;
  h1 {
    font-size: 26px;
    font-weight: 600;
    margin: 0;
    text-align: left;
  }
`;

export const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  text-align: left;
  padding: 20px;
  p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #fe4b4c;
    cursor: pointer;
  }
`;
