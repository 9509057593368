import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { FiEdit2 } from "react-icons/fi";
import { HiDotsVertical } from "react-icons/hi";
import { BsEmojiSmile, BsCardImage } from "react-icons/bs";

import * as Styled from "./style";

import UserImg from "../../assets/user1.png";

const Chat = () => {
  const [isDetail, setIsDetail] = useState(false);
  const [selected, setSelected] = useState(0);
  return (
    <React.Fragment>
      <Styled.ChatWrapper>
        <Helmet>
          <title>Chat Page | Galambo</title>
          <meta name="description" content="Chat page of galambo" />

          <link rel="canonical" href="http://www.galambo.com/message" />
        </Helmet>
        <Styled.ChatContainer>
          <Styled.UserListContainer>
            <Styled.userListHeader>
              <h1>Messages</h1>
              <FiEdit2 />
            </Styled.userListHeader>
            <Styled.UserListCotnent>
              {[...Array(13)].map((item, key) => (
                <div
                  key={key}
                  className={selected === key ? "active-chat" : ""}
                  onClick={() => setSelected(key)}
                >
                  <img src={UserImg} alt="user avatar" />
                  <span>Olivia Rhye</span>
                </div>
              ))}
            </Styled.UserListCotnent>
          </Styled.UserListContainer>
          <Styled.MessageContainr>
            <Styled.MessageHeader>
              <nav>
                <div>
                  <img src={UserImg} alt="user avator" />
                  <p>Olivia Rhye</p>
                </div>
                <HiDotsVertical
                  size={30}
                  onClick={() => setIsDetail(!isDetail)}
                />
              </nav>
            </Styled.MessageHeader>
            <Styled.MessageContent></Styled.MessageContent>
            <Styled.MessageInput>
              <div>
                <input placeholder="Message" />
                <BsEmojiSmile size={18} />
                <BsCardImage size={18} />
              </div>
            </Styled.MessageInput>
          </Styled.MessageContainr>
          {isDetail && (
            <Styled.DetailContainer>
              <Styled.DetailHeader>
                <h1>Details</h1>
              </Styled.DetailHeader>
              <Styled.DetailContent>
                <p>Block</p>
                <p>Delete Chat</p>
              </Styled.DetailContent>
            </Styled.DetailContainer>
          )}
        </Styled.ChatContainer>
      </Styled.ChatWrapper>
    </React.Fragment>
  );
};

export default Chat;
