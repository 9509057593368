import React, { useState, useRef, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../context/AppContext";
import defaultUser from "../../assets/header/default.png";

import * as Styled from "./style";

const EditPorfile = () => {
  const [context] = useAppContext();
  const [avatarURL, setAvatarURL] = useState(defaultUser);
  const [isEdit, setEdit] = useState(false);
  const [bio, setBio] = useState(
    "Dogs are domesticated carnivorous mammals that are widely kept as pets or working animals. They are known for their loyalty, companionship, and diverse behavioral and physical traits."
  );
  //   const navigate = useNavigate();
  const fileUploadRef = useRef();
  //   const logoutUser = () => {
  //     setContext({ ...context, auth: null });
  //     localStorage.clear();
  //     navigate("/login");
  //   };

  useEffect(() => {
    if (context.auth !== null) {
      if (context.auth.user.photo) {
        setAvatarURL(context.auth.user.photo);
      }
    }
  }, [context]);

  const handleImageUpload = (event) => {
    event.preventDefault();
    fileUploadRef.current.click();
  };

  const uploadImageDisplay = async () => {
    const uploadedFile = fileUploadRef.current.files[0];
    const cachedURL = URL.createObjectURL(uploadedFile);
    setAvatarURL(cachedURL);
  };

  return (
    <React.Fragment>
      <nav>
        <img src={avatarURL} alt="user-avator" />
        <form id="form" encType="multipart/form-data">
          <button type="submit" onClick={handleImageUpload}>
            Edit Photo
          </button>
          <input
            type="file"
            id="file"
            ref={fileUploadRef}
            onChange={uploadImageDisplay}
            hidden
          />
        </form>
      </nav>
      <div>
        <h1>Edit Profile</h1>
        <span>Bio</span>
        <textarea
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          disabled={!isEdit}
        />
        <Styled.EditBtns>
          {isEdit ? (
            <div>
              <button
                onClick={() => setEdit(false)}
                style={{ backgroundColor: "#F6F6F6", color: "black" }}
              >
                Discard
              </button>
              <button onClick={() => setEdit(false)}>Save</button>
            </div>
          ) : (
            <button onClick={() => setEdit(true)}>Edit Bio</button>
          )}
        </Styled.EditBtns>
      </div>
    </React.Fragment>
  );
};

export default EditPorfile;
